import React from 'react';

const ContactText = () => {
    return (
        <svg 
            width="51" 
            height="55" 
            viewBox="0 0 51 55" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="contact-text"
        >
            <path d="M4.07052 8.38752C3.3483 8.40513 2.71415 8.24659 2.1857 7.92952C1.65724 7.61245 1.25209 7.15446 0.952632 6.57315C0.670788 5.99186 0.51225 5.32248 0.494635 4.58264C0.47702 3.82519 0.617939 3.1382 0.899783 2.5569C1.18163 1.9756 1.56916 1.49999 2.09762 1.1653C2.60846 0.830616 3.22499 0.654464 3.9296 0.636849C4.49329 0.619234 5.00413 0.724925 5.46212 0.918691C5.92012 1.13007 6.27243 1.41192 6.55427 1.78183C6.83611 2.15175 7.01226 2.59213 7.06511 3.10297L5.63828 3.12059C5.5502 2.76828 5.37405 2.46882 5.09221 2.22221C4.81036 1.9756 4.44044 1.85229 3.96483 1.85229C3.55968 1.85229 3.20738 1.9756 2.90792 2.18698C2.60846 2.39836 2.37946 2.71544 2.20331 3.10297C2.04477 3.4905 1.9567 3.96611 1.97431 4.49457C1.99193 5.04064 2.08 5.51625 2.23854 5.90378C2.41469 6.29131 2.64369 6.60838 2.96077 6.81977C3.27784 7.03115 3.63014 7.13684 4.05291 7.13684C4.33475 7.13684 4.58137 7.08399 4.81036 6.9783C5.03936 6.87261 5.21551 6.71408 5.37405 6.52031C5.53258 6.32654 5.63828 6.09755 5.69112 5.8157L7.11796 5.79809C7.06511 6.29131 6.92419 6.71408 6.65996 7.10161C6.39573 7.48914 6.06105 7.7886 5.62066 8.0176C5.18028 8.26421 4.65182 8.38752 4.07052 8.38752Z" fill="white"/>
            <path d="M11.6451 9.16261C10.9581 9.02169 10.3768 8.73984 9.93642 8.31708C9.47843 7.89431 9.17896 7.36586 9.00281 6.74933C8.82666 6.11518 8.82666 5.42819 8.9852 4.67073C9.14373 3.91328 9.40796 3.26152 9.81311 2.75068C10.2183 2.23984 10.6939 1.86992 11.2752 1.65854C11.8565 1.44715 12.4906 1.41192 13.1776 1.55285C13.8646 1.69377 14.4459 1.97561 14.8863 2.39838C15.3443 2.82114 15.6438 3.34959 15.8199 3.96613C15.9961 4.60027 15.9961 5.28727 15.8375 6.06234C15.679 6.81979 15.4148 7.45393 15.0096 7.96477C14.6045 8.47562 14.1288 8.84554 13.5475 9.05692C12.9662 9.25069 12.3321 9.28591 11.6451 9.16261ZM11.8917 7.94716C12.3321 8.03524 12.7372 8.00001 13.0719 7.82385C13.4066 7.6477 13.6885 7.38347 13.9175 7.01356C14.1465 6.64364 14.305 6.23849 14.3931 5.76288C14.4812 5.28727 14.4988 4.84689 14.4459 4.42412C14.3931 4.00136 14.2345 3.63144 13.9879 3.34959C13.7413 3.05014 13.389 2.85637 12.9486 2.76829C12.4906 2.68022 12.1031 2.71545 11.7508 2.90921C11.3985 3.08537 11.1166 3.36721 10.9053 3.73713C10.6763 4.10705 10.5177 4.52981 10.4296 4.98781C10.3416 5.46342 10.324 5.9038 10.3768 6.32656C10.4473 6.74933 10.5882 7.10163 10.8524 7.40109C11.0814 7.66532 11.4337 7.85908 11.8917 7.94716Z" fill="white"/>
            <path d="M19.6247 6.66125L17.8456 10.748L16.5068 10.1667L19.4838 3.27914L20.7697 3.84283L20.2765 4.9702L20.3646 5.00543C20.6816 4.70597 21.0516 4.52982 21.4919 4.44174C21.9323 4.37128 22.4079 4.44174 22.9188 4.67074C23.3944 4.88212 23.7643 5.14635 24.0285 5.49865C24.2928 5.85096 24.4513 6.2561 24.4689 6.73171C24.5041 7.20732 24.3808 7.71816 24.1342 8.28185L22.2318 12.668L20.893 12.0867L22.725 7.85909C22.9364 7.36586 22.9892 6.90787 22.8483 6.52033C22.7074 6.1328 22.4079 5.83334 21.9675 5.63958C21.6505 5.49865 21.351 5.44581 21.0516 5.48104C20.7521 5.51627 20.4703 5.62196 20.2236 5.81573C19.977 6.00949 19.7833 6.30895 19.6247 6.66125Z" fill="white"/>
            <path d="M29.4012 6.16803L30.6166 6.96071L26.7237 12.9146C26.5651 13.1436 26.4947 13.355 26.4771 13.5136C26.4771 13.6721 26.5123 13.813 26.6004 13.9363C26.6884 14.0596 26.7941 14.1477 26.9351 14.2358C27.0408 14.3062 27.1288 14.3415 27.1993 14.3767C27.2697 14.4119 27.3402 14.4296 27.393 14.4648L26.9527 15.6274C26.8646 15.6098 26.7413 15.5745 26.6004 15.5217C26.4594 15.4688 26.2833 15.3808 26.0719 15.2398C25.7548 15.0285 25.4906 14.7819 25.2792 14.4824C25.0855 14.1829 24.9798 13.8482 24.9622 13.4783C24.9622 13.1084 25.0678 12.7385 25.3145 12.3686L29.4012 6.16803ZM30.9161 9.32115L30.2819 10.3076L26.847 8.05285L27.4811 7.0664L30.9161 9.32115Z" fill="white"/>
            <path d="M29.595 18.1111C29.2427 17.794 28.9961 17.4241 28.82 17.0366C28.6614 16.649 28.6086 16.2439 28.6791 15.8563C28.7495 15.4512 28.9433 15.0813 29.278 14.7114C29.5598 14.3943 29.8593 14.2005 30.1587 14.1124C30.4582 14.0244 30.7753 14.0068 31.0923 14.0772C31.4094 14.1477 31.7089 14.271 32.0259 14.4471C32.3254 14.6233 32.6249 14.8171 32.9243 15.0284C33.2942 15.2927 33.5937 15.504 33.8227 15.6802C34.0517 15.8387 34.2454 15.9444 34.3864 15.962C34.5273 15.9973 34.6682 15.9444 34.7915 15.8035L34.8091 15.7859C35.091 15.4688 35.2319 15.1517 35.2143 14.8171C35.1967 14.4824 35.0205 14.1477 34.6682 13.8306C34.2983 13.4959 33.946 13.3198 33.5937 13.2845C33.2414 13.2493 32.9243 13.3021 32.6777 13.4255L31.885 12.2629C32.3078 12.0339 32.7482 11.9282 33.1709 11.9282C33.5937 11.9282 33.9988 12.0339 34.3864 12.21C34.7739 12.3862 35.1438 12.6328 35.4785 12.9498C35.7075 13.1612 35.9013 13.3902 36.1126 13.6721C36.3064 13.9539 36.4649 14.2534 36.553 14.6057C36.6411 14.9404 36.6411 15.2927 36.553 15.6802C36.4649 16.0677 36.2183 16.4553 35.8484 16.8604L32.4663 20.5244L31.4094 19.5555L32.114 18.7981L32.0788 18.7628C31.885 18.8333 31.656 18.8862 31.3918 18.8862C31.1276 18.8862 30.8457 18.8333 30.5463 18.7276C30.194 18.5867 29.8945 18.3929 29.595 18.1111ZM30.6167 17.4593C30.9162 17.7412 31.2156 17.9173 31.5503 17.9878C31.8674 18.0582 32.1669 18.0582 32.4663 17.9702C32.7482 17.8821 32.9948 17.7236 33.2061 17.5122L33.8579 16.8076C33.7874 16.8076 33.6818 16.7723 33.5408 16.7019C33.3999 16.6314 33.259 16.5433 33.1005 16.4376C32.9419 16.332 32.7834 16.2263 32.6425 16.1206C32.5015 16.0149 32.3782 15.9268 32.2725 15.8563C32.0435 15.6978 31.8145 15.5569 31.5856 15.4688C31.3566 15.3807 31.1452 15.3279 30.9338 15.3631C30.7224 15.3807 30.5286 15.4864 30.3525 15.6802C30.1059 15.9444 30.0178 16.2439 30.0883 16.561C30.1411 16.8956 30.3173 17.1775 30.6167 17.4593Z" fill="white"/>
            <path d="M35.3904 24.523C34.9676 23.9417 34.721 23.3252 34.6858 22.7086C34.6505 22.0921 34.7738 21.4932 35.0909 20.9295C35.408 20.3658 35.8484 19.855 36.4649 19.4146C37.0814 18.9742 37.7156 18.6924 38.3497 18.5691C38.9839 18.4458 39.6004 18.5162 40.1641 18.7452C40.7278 18.9742 41.2386 19.3794 41.6438 19.9431C41.9784 20.4011 42.1898 20.8767 42.2779 21.3699C42.366 21.8631 42.3484 22.3211 42.2074 22.7615C42.0665 23.2019 41.8023 23.607 41.4324 23.9417L40.6045 22.7967C40.8511 22.5325 40.992 22.1978 41.0272 21.8279C41.0625 21.458 40.9568 21.0704 40.6749 20.7005C40.4283 20.3658 40.1465 20.1368 39.7942 20.0311C39.4419 19.9078 39.0543 19.8902 38.6316 19.9959C38.2088 20.084 37.786 20.2954 37.3633 20.6124C36.9229 20.9295 36.5882 21.2818 36.3592 21.6517C36.1302 22.0217 36.0245 22.3916 36.0245 22.7615C36.0245 23.1314 36.1478 23.4837 36.3944 23.8184C36.553 24.0474 36.7467 24.2236 36.9581 24.3469C37.1695 24.4702 37.3985 24.5406 37.6451 24.5582C37.8917 24.5759 38.1383 24.523 38.4026 24.4173L39.2305 25.5623C38.8077 25.8089 38.3673 25.9322 37.8917 25.9498C37.4337 25.9675 36.9757 25.8442 36.5354 25.6152C36.1302 25.3509 35.7427 24.9986 35.3904 24.523Z" fill="white"/>
            <path d="M46.5407 24.6111L47.1924 25.9146L40.8333 29.0853C40.5867 29.2086 40.4106 29.3496 40.3225 29.4905C40.2344 29.6314 40.1992 29.7723 40.2168 29.9133C40.2344 30.0542 40.2697 30.1951 40.3401 30.336C40.393 30.4417 40.4458 30.5298 40.4987 30.6002C40.5515 30.6707 40.5867 30.7235 40.622 30.7588L39.6531 31.5339C39.5827 31.481 39.4946 31.3753 39.4065 31.252C39.3184 31.1287 39.1951 30.9525 39.0894 30.7588C38.9133 30.4241 38.8076 30.0718 38.79 29.7019C38.7724 29.332 38.8428 28.9973 39.019 28.6802C39.1951 28.3631 39.4946 28.0989 39.8997 27.9051L46.5407 24.6111ZM46.2764 28.0989L45.2195 28.6273L43.3875 24.9634L44.4445 24.4349L46.2764 28.0989Z" fill="white"/>
            <path d="M41.6084 36.29L49.042 35.1978L49.2534 36.5894L48.0379 36.7656L48.0555 36.8536C48.4959 36.9417 48.8482 37.1531 49.1301 37.4702C49.4119 37.7872 49.5881 38.1924 49.6585 38.6856C49.729 39.1788 49.6761 39.6192 49.5 39.9891C49.3238 40.3591 49.042 40.6585 48.6545 40.8699L48.6721 40.9404C49.0948 41.0637 49.4471 41.2927 49.7466 41.645C50.0461 41.9973 50.2222 42.4377 50.3103 42.9837C50.416 43.6531 50.2751 44.2344 49.9228 44.7276C49.5705 45.2209 48.9539 45.5203 48.1084 45.6436L43.1233 46.3658L42.9119 44.9214L47.7561 44.2168C48.2669 44.1463 48.6016 43.9526 48.7954 43.6531C48.9891 43.3536 49.042 43.0013 48.9892 42.6138C48.9187 42.1382 48.7249 41.8035 48.3902 41.5745C48.0555 41.3631 47.668 41.2751 47.2276 41.3455L42.4892 42.0325L42.2778 40.5881L47.21 39.8658C47.6152 39.813 47.9146 39.6368 48.126 39.3374C48.3374 39.0555 48.4078 38.7032 48.355 38.2981C48.3198 38.0162 48.2141 37.7872 48.0203 37.5759C47.8442 37.3645 47.6152 37.2059 47.3333 37.1003C47.0515 36.9946 46.752 36.9769 46.4173 37.0122L41.8374 37.6816L41.6084 36.29Z" fill="white"/>
            <path d="M42.3659 51.3333C42.4364 50.5935 42.6653 49.977 43.0353 49.4837C43.4052 48.9905 43.8984 48.6206 44.4973 48.4092C45.0963 48.1978 45.8009 48.1097 46.5759 48.1978C47.3334 48.2859 48.0028 48.4973 48.5488 48.8496C49.0949 49.2019 49.5177 49.6423 49.7819 50.206C50.0461 50.7696 50.1518 51.3862 50.0813 52.0732C50.0285 52.4959 49.9228 52.9011 49.7467 53.271C49.5705 53.6409 49.3063 53.9756 48.9716 54.2574C48.6369 54.5393 48.2318 54.7507 47.7385 54.874C47.2453 54.9973 46.664 55.0325 45.977 54.9621L45.4662 54.9092L46.1003 48.9729L47.1925 49.0962L46.7168 53.6057C47.1044 53.6409 47.4391 53.6057 47.7562 53.4824C48.0732 53.3591 48.3198 53.1653 48.5312 52.9011C48.725 52.6368 48.8483 52.3198 48.9011 51.9499C48.9364 51.5447 48.8835 51.1748 48.725 50.8577C48.5664 50.5406 48.3198 50.2764 48.0204 50.0826C47.7209 49.8889 47.3862 49.7656 47.0163 49.7303L46.1708 49.6423C45.6776 49.5894 45.2372 49.6246 44.8673 49.7656C44.4973 49.9065 44.1979 50.1179 43.9865 50.4173C43.7751 50.7168 43.6342 51.0691 43.599 51.5095C43.5637 51.7913 43.5813 52.0379 43.6342 52.2845C43.687 52.5135 43.7927 52.7249 43.9336 52.9187C44.0746 53.0948 44.2507 53.2534 44.4797 53.3591L44.0922 54.7154C43.7046 54.5569 43.37 54.3279 43.0881 54.0108C42.8239 53.6938 42.6125 53.3062 42.4892 52.8658C42.3307 52.3726 42.313 51.8794 42.3659 51.3333Z" fill="white"/>
        </svg>
    )
}

export default ContactText;